body {
  padding: 0;
  margin: 0;
  background-color: rgb(252, 252, 252);
  padding-bottom: 30px;
}

.customScroll ::-webkit-scrollbar {
  width: 0.1px;
}

.container {
  padding: 10px 10px 10px 10px;
  max-width: 80%;
  margin: 0 auto;
}

.h-v-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.breadcrumb {
  padding: 64px 5px 5px 5px;
  max-width: 100%;
  margin: 0 auto;
  background-color: rgb(239, 240, 240);
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.loading-overlay-content {
  position: absolute;
  top: 50%;
  left: 48%;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
}

.cursor-pointer {
  cursor: pointer;
}

.button-transparent {
  background-color: transparent;
  border: none;
}

.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded.Mui-focusVisible {
  background-color: #1976d2;
}

.dashboard-header {
  font-size: 10px;
  background-color: rgba(88, 88, 88, 0.55);
  word-break: break-all;
  line-height: 1;
}

.dashboard-header .MuiDataGrid-columnHeaderTitle {
  white-space: break-spaces!important;
}

.dashboard-header:hover {
  word-break: inherit;
}

@media (min-width: 1500px) {
  .dashboard-header {
    font-size: 11px;
  }
}

.dashboard-cell {
  font-size: 11px;
  word-break: break-all;
}

.dashboard-cell-break-word {
  font-size: 11px;
  word-wrap: break-word;
}

#world-map {
  fill: #78909c;
  stroke: white;
  .country-map:hover {
    fill: #b2102f
    ;
  }
}

.wrap-cell-scroll div {
  word-wrap: break-word!important;
  max-height: 100%!important;
  overflow: auto!important;
}