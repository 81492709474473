.card-pipeline-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  margin-top: 4rem;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.card-pipeline.first {
  width: 155px;
  background-image: url("data:image/svg+xml,%3Csvg width='155' height='131' viewBox='0 0 155 131' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 9C0 4.02944 4.02944 0 9 0H125.181C128.98 0 132.371 2.38595 133.653 5.96242L153.911 62.4624C154.615 64.4263 154.615 66.5737 153.911 68.5376L133.653 125.038C132.371 128.614 128.98 131 125.181 131H8.99999C4.02943 131 0 126.971 0 122V65.5V9Z' fill='%238D8D8D'/%3E%3C/svg%3E");
  background-size: cover;
}

.card-pipeline.first.selected {
  width: 155px;
  background-image: url("data:image/svg+xml,%3Csvg width='155' height='131' viewBox='0 0 155 131' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 9C0 4.02944 4.02944 0 9 0H125.181C128.98 0 132.371 2.38595 133.653 5.96242L153.911 62.4624C154.615 64.4263 154.615 66.5737 153.911 68.5376L133.653 125.038C132.371 128.614 128.98 131 125.181 131H8.99999C4.02943 131 0 126.971 0 122V65.5V9Z' fill='%230045AA'/%3E%3C/svg%3E");
  background-size: cover;
}

.card-pipeline {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 152px;
  height: 131px;
  background-image: url("data:image/svg+xml,%3Csvg width='152' height='131' viewBox='0 0 152 131' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.31604 12.0376C-0.786143 6.17452 3.55942 0 9.78794 0H122.181C125.98 0 129.371 2.38595 130.653 5.96242L150.911 62.4624C151.615 64.4263 151.615 66.5737 150.911 68.5376L130.653 125.038C129.371 128.614 125.98 131 122.181 131H9.78793C3.55941 131 -0.78614 124.825 1.31604 118.962L19.3957 68.5376C20.0999 66.5737 20.0999 64.4263 19.3957 62.4624L1.31604 12.0376Z' fill='%238D8D8D'/%3E%3C/svg%3E");
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: ease-in-out 0.3s;
}

.card-pipeline.selected {
  width: 152px;
  background-image: url("data:image/svg+xml,%3Csvg width='152' height='131' viewBox='0 0 152 131' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.31604 12.0376C-0.786143 6.17452 3.55942 0 9.78794 0H122.181C125.98 0 129.371 2.38595 130.653 5.96242L150.911 62.4624C151.615 64.4263 151.615 66.5737 150.911 68.5376L130.653 125.038C129.371 128.614 125.98 131 122.181 131H9.78793C3.55941 131 -0.78614 124.825 1.31604 118.962L19.3957 68.5376C20.0999 66.5737 20.0999 64.4263 19.3957 62.4624L1.31604 12.0376Z' fill='%230045AA'/%3E%3C/svg%3E");
  background-size: cover;
}

.row {
  display: flex;
  align-items: center;
}

.row-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.circle {
  min-width: 1.1rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  transition: ease-in-out 0.2s;
  padding: 3px 7px;
}

.circle.in-sla:hover {
  background-color: #44c305;
  color: #fff;
}

.circle.in-sla.selected {
  background-color: #44c305;
  color: #fff;
}

.circle.out-of-sla:hover {
  background-color: #E50101;
  color: #fff;
}

.circle.out-of-sla.selected {
  background-color: #E50101;
  color: #fff;
}

.badge {
  background-color: #a5aab0;
  color: #fff;
  padding: 5px 8px;
  border-radius: 0.5rem;
  font-size: 11px;
  transition: ease-in-out 0.2s;
}

.badge.in-sla {
  background-color: #44c305;
}

.badge.out-of-sla {
  background-color: #E50101;
}

.totals {
  padding: 0 25px 5px 10px;
}

.icon-card-pipeline {
  padding-right: 12px;
}

.icon-area {
  padding: 10px 10px 0 12px;
}

.card-pipeline + .card-pipeline {
  margin-left: -1.5rem;
}

.card-pipeline:nth-child(odd) {
  margin-left: 0;
}

@media (min-width: 426px) {
  .card-pipeline:nth-child(odd) {
    margin-left: -1.5rem;
  }

  .card-pipeline:first-child {
    margin-left: 0;
  }

  .card-pipeline:nth-child(5n) {
    margin-left: 0;
  }
}

@media (min-width: 767px) {
  .card-pipeline:nth-child(odd) {
    margin-left: -1.5rem;
  }

  .card-pipeline:first-child {
    margin-left: 0;
  }

  .card-pipeline:nth-child(6n) {
    margin-left: 0;
  }

  .card-pipeline.first {
    width: 165px;
    height: 141px;
  }
  
  .card-pipeline.first.selected {
    width: 165px;
    height: 141px;
  }
  
  .card-pipeline {
    width: 162px;
    height: 141px;
  }

  .card-pipeline.selected {
    width: 162px;
    height: 141px;
  }

  .totals {
    padding: 0 35px 15px 10px;
  }
}

@media (min-width: 767px) {
  .card-pipeline.first {
    width: 185px;
    height: 155px;
  }
  
  .card-pipeline.first.selected {
    width: 185px;
    height: 155px;
  }
  
  .card-pipeline {
    width: 182px;
    height: 155px;
  }

  .card-pipeline.selected {
    width: 182px;
    height: 155px;
  }

  .totals {
    padding: 0 30px 5px 10px;
  }
}

@media (min-width: 1000px) {
  .card-pipeline-container {
    flex-wrap: nowrap;
  }
  
  .card-pipeline + .card-pipeline {
    margin-left: -1.4rem;
  }

  .card-pipeline:first-child {
    margin-left: 0;
  }

  .card-pipeline.first {
    width: 13vw;
    height: 11vw;
  }
  
  .card-pipeline.first.selected {
    width: 13vw;
    height: 11vw;
  }
  
  .card-pipeline {
    width: 13vw;
    height: 11vw;
  }

  .card-pipeline.selected {
    width: 13vw;
    height: 11vw;
  }

  .totals {
    padding: 0 25px 5px 10px;
  }

  .icon-area {
    padding: 25px 35px 0 30px;
  }
}

@media (min-width: 1320px) {
  .card-pipeline + .card-pipeline {
    margin-left: -1.4rem;
  }

  .card-pipeline:first-child {
    margin-left: 0;
  }

  .card-pipeline.first {
    width: 12.8vw;
    height: 10.8vw;
  }
  
  .card-pipeline.first.selected {
    width: 12.8vw;
    height: 10.8vw;
  }
  
  .card-pipeline {
    width: 12.8vw;
    height: 10.8vw;
  }

  .card-pipeline.selected {
    width: 12.8vw;
    height: 10.8vw;
  }

  .totals {
    padding: 0 35px 5px 10px;
  }
}
