input[type="file"]::file-selector-button {
  border-radius: 4px;
  padding: 6px 16px;
  height: 40px;
  cursor: pointer;
  background-color: #1976d2;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  margin-right: 16px;
  transition: background-color 200ms;
  color: white;
}

input[type="file"]::file-selector-button:hover {
  background-color: #1565c0;
}

input[type="file"]::file-selector-button:active {
  background-color: #1565c0;
}

input[type="file"] {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  border: 1px;
}