.card-pipeline-triage-first {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 133px;
  background-image: url("data:image/svg+xml,%3Csvg width='155' height='133' viewBox='0 0 155 131' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 9C0 4.02944 4.02944 0 9 0H125.181C128.98 0 132.371 2.38595 133.653 5.96242L153.911 62.4624C154.615 64.4263 154.615 66.5737 153.911 68.5376L133.653 125.038C132.371 128.614 128.98 131 125.181 131H8.99999C4.02943 131 0 126.971 0 122V65.5V9Z' fill='%238D8D8D'/%3E%3C/svg%3E");
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}

.card-pipeline-triage-first-selected {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 133px;
  background-image: url("data:image/svg+xml,%3Csvg width='155' height='131' viewBox='0 0 155 131' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 9C0 4.02944 4.02944 0 9 0H125.181C128.98 0 132.371 2.38595 133.653 5.96242L153.911 62.4624C154.615 64.4263 154.615 66.5737 153.911 68.5376L133.653 125.038C132.371 128.614 128.98 131 125.181 131H8.99999C4.02943 131 0 126.971 0 122V65.5V9Z' fill='%230045AA'/%3E%3C/svg%3E");
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}

.card-pipeline-triage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 133px;
  background-image: url("data:image/svg+xml,%3Csvg width='152' height='131' viewBox='0 0 152 131' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.31604 12.0376C-0.786143 6.17452 3.55942 0 9.78794 0H122.181C125.98 0 129.371 2.38595 130.653 5.96242L150.911 62.4624C151.615 64.4263 151.615 66.5737 150.911 68.5376L130.653 125.038C129.371 128.614 125.98 131 122.181 131H9.78793C3.55941 131 -0.78614 124.825 1.31604 118.962L19.3957 68.5376C20.0999 66.5737 20.0999 64.4263 19.3957 62.4624L1.31604 12.0376Z' fill='%238D8D8D'/%3E%3C/svg%3E");
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: ease-in-out 0.3s;
}

.card-pipeline-triage-selected {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 133px;
  background-image: url("data:image/svg+xml,%3Csvg width='152' height='131' viewBox='0 0 152 131' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.31604 12.0376C-0.786143 6.17452 3.55942 0 9.78794 0H122.181C125.98 0 129.371 2.38595 130.653 5.96242L150.911 62.4624C151.615 64.4263 151.615 66.5737 150.911 68.5376L130.653 125.038C129.371 128.614 125.98 131 122.181 131H9.78793C3.55941 131 -0.78614 124.825 1.31604 118.962L19.3957 68.5376C20.0999 66.5737 20.0999 64.4263 19.3957 62.4624L1.31604 12.0376Z' fill='%230045AA'/%3E%3C/svg%3E");
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}

.inner-card {
  padding-right: 10px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.row {
  display: flex;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.triage-circle {
  min-width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  transition: ease-in-out 0.2s;
}

.triage-circle-selected {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: #E50101;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.triage-totals {
  padding-right: 20px;
  padding-bottom: 10px;
}

.icon-card-pipeline {
  padding-right: 10px;
}

.triage-icon-area {
  padding: 15px 25px 0 12px;
}

@media (max-width: 760px) {
  .card-pipeline-first {
    width: 100%;
    background-image: none;
    background-color: #8D8D8D;
    border-radius: 10px;
  }

  .card-pipeline {
    background-image: none;
    background-color: #8D8D8D;
    width: 100%;
    border-radius: 10px;
  }

  .card-pipeline-first-selected {
    width: 100%;
    background-image: none;
    background-color: #0045AA;
    border-radius: 10px;
  }

  .card-pipeline-selected {
    width: 100%;
    background-image: none;
    background-color: #0045AA;
    border-radius: 10px;
  }

  .triage-totals {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 20px;
  }
}

@media (max-width: 899px) {
  .card-pipeline-first {
    width: 100%;
    background-image: none;
    background-color: #8D8D8D;
    border-radius: 10px;
  }

  .card-pipeline {
    background-image: none;
    background-color: #8D8D8D;
    width: 100%;
    border-radius: 10px;
  }

  .card-pipeline-first-selected {
    width: 100%;
    background-image: none;
    background-color: #0045AA;
    border-radius: 10px;
  }

  .card-pipeline-selected {
    width: 100%;
    background-image: none;
    background-color: #0045AA;
    border-radius: 10px;
  }

  .triage-totals {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 20px;
  }
}